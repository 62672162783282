import './App.css';
import Navbar from './componenst/Navbar';
//import Aside from './componenst/Aside'
import Footer from "./componenst/Footer";
import Home from './componenst/Home';
import Torneo from './componenst/Torneo';

import Error404 from './componenst/Error404';
//import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/torneo' element={<Torneo/>}/>
        
        <Route path='*' element={<Error404/>}/>
      </Routes>
      <Footer />
</BrowserRouter>
  );
}

export default App;
