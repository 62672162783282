import React from "react";
import { NavLink } from "react-router-dom";
//import logo from "../assets/img/proyecto/logo/logo.png";
import user1_128x128 from "../assets/img/img/user1-128x128.jpg";
import user2_160x160 from "../assets/img/img/user2-160x160.jpg";
import user3_128x128 from "../assets/img/img/user3-128x128.jpg";
import user4_128x128 from "../assets/img/img/user4-128x128.jpg";
import user5_128x128 from "../assets/img/img/user5-128x128.jpg";
import user6_128x128 from "../assets/img/img/user6-128x128.jpg";
import user7_128x128 from "../assets/img/img/user7-128x128.jpg";
import user8_128x128 from "../assets/img/img/user8-128x128.jpg";

import styled from "styled-components";

import { useTranslation } from "react-i18next";

export default function Body() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <BodyContainer>
        <body>
          <br></br>
          <br></br>

          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              {/* Info boxes */}
              <div className="row">
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="info-box">
                    <span className="info-box-icon bg-info elevation-1">
                      <i className="fas fa-cog" />
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text">Más juegos ganados</span>
                      <span className="info-box-text">
                        Yoislán Martínez Aranda
                      </span>
                      <span className="info-box-number">95</span>
                    </div>
                    {/* /.info-box-content */}
                  </div>
                  {/* /.info-box */}
                </div>
                {/* /.col */}
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="info-box mb-3">
                    <span className="info-box-icon bg-danger elevation-1">
                      <i className="fas fa-thumbs-up" />
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text">Más juegos perdidos</span>
                      <span className="info-box-text">El Chiqui</span>
                      <span className="info-box-number">41</span>
                    </div>
                    {/* /.info-box-content */}
                  </div>
                  {/* /.info-box */}
                </div>
                {/* /.col */}
                {/* fix for small devices only */}
                <div className="clearfix hidden-md-up" />
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="info-box mb-3">
                    <span className="info-box-icon bg-success elevation-1">
                      <i className="fas fa-shopping-cart" />
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text">Más viajes dados</span>
                      <span className="info-box-text">Lázaro el mecánico</span>
                      <span className="info-box-number">14</span>
                    </div>
                    {/* /.info-box-content */}
                  </div>
                  {/* /.info-box */}
                </div>
                {/* /.col */}
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="info-box mb-3">
                    <span className="info-box-icon bg-warning elevation-1">
                      <i className="fas fa-users" />
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text">Ranking</span>
                      <span className="info-box-text">Eduardo el tigre</span>
                      <span className="info-box-number">98</span>
                    </div>
                    {/* /.info-box-content */}
                  </div>
                  {/* /.info-box */}
                </div>
                {/* /.col */}
                {/* /.col */}
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="info-box mb-3">
                    <span className="info-box-icon bg-black elevation-1">
                      <i className="fas fa-users" />
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text">Más juegos Jugados</span>
                      <span className="info-box-text">El flaco</span>
                      <span className="info-box-number">200</span>
                    </div>
                    {/* /.info-box-content */}
                  </div>
                  {/* /.info-box */}
                </div>
                {/* /.col */}
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="info-box">
                    <span className="info-box-icon bg-info elevation-1">
                      <i className="fas fa-cog" />
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text">Más Noches ganadas</span>
                      <span className="info-box-text">
                        Yoislán Martínez Aranda
                      </span>
                      <span className="info-box-number">17</span>
                    </div>
                    {/* /.info-box-content */}
                  </div>
                  {/* /.info-box */}
                </div>
              </div>
              {/* /.row */}
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Información General</h5>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="remove"
                        >
                          <i className="fas fa-times" />
                        </button>
                      </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="chart">
                            {/* Main content */}
                            <section className="content">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-header">
                                      <h3 className="card-title">Ranking</h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                      <table
                                        id="example1"
                                        className="table table-bordered table-striped"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Posición</th>
                                            <th>Jugador</th>
                                            <th>Puntos</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>1</td>
                                            <td>Yoislán Martínez Aranda</td>
                                            <td>95</td>
                                          </tr>
                                          <tr>
                                            <td>2</td>
                                            <td>Lázaro el mecánico</td>
                                            <td>90</td>
                                          </tr>
                                          <tr>
                                            <td>3</td>
                                            <td>Carlos Alfonso</td>
                                            <td>85</td>
                                          </tr>
                                          <tr>
                                            <td>4</td>
                                            <td>El chiqui</td>
                                            <td>75</td>
                                          </tr>
                                          <tr>
                                            <td>4</td>
                                            <td>Motorina Verde</td>
                                            <td>72</td>
                                          </tr>
                                          <tr>
                                            <td>5</td>
                                            <td>Eduardo el tigre</td>
                                            <td>70</td>
                                          </tr>
                                          <tr>
                                            <td>6</td>
                                            <td>El custodio</td>
                                            <td>58</td>
                                          </tr>
                                          <tr>
                                            <td>7</td>
                                            <td>Roberto el imaginario</td>
                                            <td>47</td>
                                          </tr>
                                          <tr>
                                            <td>8</td>
                                            <td>El flaco</td>
                                            <td>28</td>
                                          </tr>
                                        </tbody>
                                        <tfoot>
                                          <tr>
                                            <th>Posición</th>
                                            <th>Jugador</th>
                                            <th>Puntos</th>
                                          </tr>
                                        </tfoot>
                                      </table>
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}
                                </div>
                                {/* /.col */}
                              </div>
                              {/* /.row */}
                            </section>
                            {/* /.content */}
                          </div>
                          {/* /.chart-responsive */}
                        </div>
                        {/* /.col */}
                        <div className="col-md-4">
                          <p className="text-center">
                            <strong>Mejores Promedios</strong>
                          </p>
                          <div className="progress-group">
                            Eduardo el tigre
                            <span className="float-right">
                              <b>160</b>/200
                            </span>
                            <div className="progress progress-sm">
                              <div
                                className="progress-bar bg-primary"
                                style={{ width: "80%" }}
                              />
                            </div>
                          </div>
                          {/* /.progress-group */}
                          <div className="progress-group">
                            Yoislán Martínez Aranda
                            <span className="float-right">
                              <b>310</b>/400
                            </span>
                            <div className="progress progress-sm">
                              <div
                                className="progress-bar bg-danger"
                                style={{ width: "75%" }}
                              />
                            </div>
                          </div>
                          {/* /.progress-group */}
                          <div className="progress-group">
                            <span className="progress-text">
                              Motorina Verde
                            </span>
                            <span className="float-right">
                              <b>480</b>/800
                            </span>
                            <div className="progress progress-sm">
                              <div
                                className="progress-bar bg-success"
                                style={{ width: "60%" }}
                              />
                            </div>
                          </div>
                          {/* /.progress-group */}
                          <div className="progress-group">
                            Lázaro el mecánico
                            <span className="float-right">
                              <b>250</b>/500
                            </span>
                            <div className="progress progress-sm">
                              <div
                                className="progress-bar bg-warning"
                                style={{ width: "50%" }}
                              />
                            </div>
                          </div>
                          {/* /.progress-group */}
                        </div>
                        {/* /.col */}
                      </div>
                      {/* /.row */}
                    </div>
                  </div>
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      {/* DIRECT CHAT */}
                      <div className="card direct-chat direct-chat-warning">
                        <div className="card-header">
                          <h3 className="card-title">Chat</h3>
                          <div className="card-tools">
                            <span
                              data-toggle="tooltip"
                              title="3 New Messages"
                              className="badge badge-warning"
                            >
                              3
                            </span>
                            <button
                              type="button"
                              className="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i className="fas fa-minus" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-tool"
                              data-toggle="tooltip"
                              title="Contacts"
                              data-widget="chat-pane-toggle"
                            >
                              <i className="fas fa-comments" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-tool"
                              data-card-widget="remove"
                            >
                              <i className="fas fa-times" />
                            </button>
                          </div>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                          {/* Conversations are loaded here */}
                          <div className="direct-chat-messages">
                            {/* Message. Default to the left */}
                            <div className="direct-chat-msg">
                              <div className="direct-chat-infos clearfix">
                                <span className="direct-chat-name float-left">
                                  Alexander Pierce
                                </span>
                                <span className="direct-chat-timestamp float-right">
                                  23 Jan 2:00 pm
                                </span>
                              </div>
                              {/* /.direct-chat-infos */}
                              <img
                                className="direct-chat-img"
                                src={user1_128x128}
                                alt="message user image"
                              />
                              {/* /.direct-chat-img */}
                              <div className="direct-chat-text">
                                Is this template really for free? That's
                                unbelievable!
                              </div>
                              {/* /.direct-chat-text */}
                            </div>
                            {/* /.direct-chat-msg */}
                            {/* Message to the right */}
                            <div className="direct-chat-msg right">
                              <div className="direct-chat-infos clearfix">
                                <span className="direct-chat-name float-right">
                                  Sarah Bullock
                                </span>
                                <span className="direct-chat-timestamp float-left">
                                  23 Jan 2:05 pm
                                </span>
                              </div>
                              {/* /.direct-chat-infos */}
                              <img
                                className="direct-chat-img"
                                src={user3_128x128}
                                alt="message user image"
                              />
                              {/* /.direct-chat-img */}
                              <div className="direct-chat-text">
                                You better believe it!
                              </div>
                              {/* /.direct-chat-text */}
                            </div>
                            {/* /.direct-chat-msg */}
                            {/* Message. Default to the left */}
                            <div className="direct-chat-msg">
                              <div className="direct-chat-infos clearfix">
                                <span className="direct-chat-name float-left">
                                  Alexander Pierce
                                </span>
                                <span className="direct-chat-timestamp float-right">
                                  23 Jan 5:37 pm
                                </span>
                              </div>
                              {/* /.direct-chat-infos */}
                              <img
                                className="direct-chat-img"
                                src={user1_128x128}
                                alt="message user image"
                              />
                              {/* /.direct-chat-img */}
                              <div className="direct-chat-text">
                                Working with AdminLTE on a great new app! Wanna
                                join?
                              </div>
                              {/* /.direct-chat-text */}
                            </div>
                            {/* /.direct-chat-msg */}
                            {/* Message to the right */}
                            <div className="direct-chat-msg right">
                              <div className="direct-chat-infos clearfix">
                                <span className="direct-chat-name float-right">
                                  Sarah Bullock
                                </span>
                                <span className="direct-chat-timestamp float-left">
                                  23 Jan 6:10 pm
                                </span>
                              </div>
                              {/* /.direct-chat-infos */}
                              <img
                                className="direct-chat-img"
                                src={user3_128x128}
                                alt="message user image"
                              />
                              {/* /.direct-chat-img */}
                              <div className="direct-chat-text">
                                I would love to.
                              </div>
                              {/* /.direct-chat-text */}
                            </div>
                            {/* /.direct-chat-msg */}
                          </div>
                          {/*/.direct-chat-messages*/}
                          {/* Contacts are loaded here */}
                          <div className="direct-chat-contacts">
                            <ul className="contacts-list">
                              <li>
                                <a href="#">
                                  <img
                                    className="contacts-list-img"
                                    src={user1_128x128}
                                  />
                                  <div className="contacts-list-info">
                                    <span className="contacts-list-name">
                                      Count Dracula
                                      <small className="contacts-list-date float-right">
                                        2/28/2015
                                      </small>
                                    </span>
                                    <span className="contacts-list-msg">
                                      How have you been? I was...
                                    </span>
                                  </div>
                                  {/* /.contacts-list-info */}
                                </a>
                              </li>
                              {/* End Contact Item */}
                              <li>
                                <a href="#">
                                  <img
                                    className="contacts-list-img"
                                    src={user7_128x128}
                                  />
                                  <div className="contacts-list-info">
                                    <span className="contacts-list-name">
                                      Sarah Doe
                                      <small className="contacts-list-date float-right">
                                        2/23/2015
                                      </small>
                                    </span>
                                    <span className="contacts-list-msg">
                                      I will be waiting for...
                                    </span>
                                  </div>
                                  {/* /.contacts-list-info */}
                                </a>
                              </li>
                              {/* End Contact Item */}
                              <li>
                                <a href="#">
                                  <img
                                    className="contacts-list-img"
                                    src={user3_128x128}
                                  />
                                  <div className="contacts-list-info">
                                    <span className="contacts-list-name">
                                      Nadia Jolie
                                      <small className="contacts-list-date float-right">
                                        2/20/2015
                                      </small>
                                    </span>
                                    <span className="contacts-list-msg">
                                      I'll call you back at...
                                    </span>
                                  </div>
                                  {/* /.contacts-list-info */}
                                </a>
                              </li>
                              {/* End Contact Item */}
                              <li>
                                <a href="#">
                                  <img
                                    className="contacts-list-img"
                                    src={user5_128x128}
                                  />
                                  <div className="contacts-list-info">
                                    <span className="contacts-list-name">
                                      Nora S. Vans
                                      <small className="contacts-list-date float-right">
                                        2/10/2015
                                      </small>
                                    </span>
                                    <span className="contacts-list-msg">
                                      Where is your new...
                                    </span>
                                  </div>
                                  {/* /.contacts-list-info */}
                                </a>
                              </li>
                              {/* End Contact Item */}
                              <li>
                                <a href="#">
                                  <img
                                    className="contacts-list-img"
                                    src={user6_128x128}
                                  />
                                  <div className="contacts-list-info">
                                    <span className="contacts-list-name">
                                      John K.
                                      <small className="contacts-list-date float-right">
                                        1/27/2015
                                      </small>
                                    </span>
                                    <span className="contacts-list-msg">
                                      Can I take a look at...
                                    </span>
                                  </div>
                                  {/* /.contacts-list-info */}
                                </a>
                              </li>
                              {/* End Contact Item */}
                              <li>
                                <a href="#">
                                  <img
                                    className="contacts-list-img"
                                    src={user8_128x128}
                                  />
                                  <div className="contacts-list-info">
                                    <span className="contacts-list-name">
                                      Kenneth M.
                                      <small className="contacts-list-date float-right">
                                        1/4/2015
                                      </small>
                                    </span>
                                    <span className="contacts-list-msg">
                                      Never mind I found...
                                    </span>
                                  </div>
                                  {/* /.contacts-list-info */}
                                </a>
                              </li>
                              {/* End Contact Item */}
                            </ul>
                            {/* /.contacts-list */}
                          </div>
                          {/* /.direct-chat-pane */}
                        </div>
                        {/* /.card-body */}
                        <div className="card-footer">
                          <form action="#" method="post">
                            <div className="input-group">
                              <input
                                type="text"
                                name="message"
                                placeholder="Type Message ..."
                                className="form-control"
                              />
                              <span className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                >
                                  Send
                                </button>
                              </span>
                            </div>
                          </form>
                        </div>
                        {/* /.card-footer*/}
                      </div>
                      {/*/.direct-chat */}
                    </div>
                    {/* /.col */}
                    <div className="col-md-6">
                      {/* USERS LIST */}
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Jugadores</h3>
                          <div className="card-tools">
                            <span className="badge badge-danger">
                              8 New Members
                            </span>
                            <button
                              type="button"
                              className="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i className="fas fa-minus" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-tool"
                              data-card-widget="remove"
                            >
                              <i className="fas fa-times" />
                            </button>
                          </div>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body p-0">
                          <ul className="users-list clearfix">
                            <li>
                              <img src={user1_128x128} alt="User Image" />
                              <a className="users-list-name" href="#">
                                Alexander Pierce
                              </a>
                              <span className="users-list-date">Today</span>
                            </li>
                            <li>
                              <img src={user8_128x128} alt="User Image" />
                              <a className="users-list-name" href="#">
                                Norman
                              </a>
                              <span className="users-list-date">Yesterday</span>
                            </li>
                            <li>
                              <img src={user7_128x128} alt="User Image" />
                              <a className="users-list-name" href="#">
                                Jane
                              </a>
                              <span className="users-list-date">12 Jan</span>
                            </li>
                            <li>
                              <img src={user6_128x128} alt="User Image" />
                              <a className="users-list-name" href="#">
                                John
                              </a>
                              <span className="users-list-date">12 Jan</span>
                            </li>
                            <li>
                              <img src={user2_160x160} alt="User Image" />
                              <a className="users-list-name" href="#">
                                Alexander
                              </a>
                              <span className="users-list-date">13 Jan</span>
                            </li>
                            <li>
                              <img src={user5_128x128} alt="User Image" />
                              <a className="users-list-name" href="#">
                                Sarah
                              </a>
                              <span className="users-list-date">14 Jan</span>
                            </li>
                            <li>
                              <img src={user4_128x128} alt="User Image" />
                              <a className="users-list-name" href="#">
                                Nora
                              </a>
                              <span className="users-list-date">15 Jan</span>
                            </li>
                            <li>
                              <img src={user3_128x128} alt="User Image" />
                              <a className="users-list-name" href="#">
                                Nadia
                              </a>
                              <span className="users-list-date">15 Jan</span>
                            </li>
                          </ul>
                          {/* /.users-list */}
                        </div>
                        {/* /.card-body */}
                        <div className="card-footer text-center">
                          <a href="javascript::">View All Users</a>
                        </div>
                        {/* /.card-footer */}
                      </div>
                      {/*/.card */}
                    </div>
                    {/* /.col */}
                  </div>
                  {/* /.row */}
                </div>
              </div>
              {/* /.row */}
            </div>
            {/*/. container-fluid */}
          </section>
          {/* /.content */}

          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Viajes</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Posición</th>
                          <th>Jugador</th>
                          <th>Ha Dado</th>
                          <th>Le Han Dado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Yoislán Martínez Aranda</td>
                          <td>15</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Lázaro el mecánico</td>
                          <td>13</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Carlos Alfonso</td>
                          <td>11</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>El chiqui</td>
                          <td>10</td>
                          <td>20</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Motorina Verde</td>
                          <td>7</td>
                          <td>9</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Eduardo el tigre</td>
                          <td>7</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>El custodio</td>
                          <td>5</td>
                          <td>8</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Roberto el imaginario</td>
                          <td>4</td>
                          <td>15</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>El flaco</td>
                          <td>2</td>
                          <td>9</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Posición</th>
                          <th>Jugador</th>
                          <th>Ha Dado</th>
                          <th>Le Han Dado</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}


          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Histórico</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Jugador</th>
                          <th>Viajes</th>
                          <th>Pollos</th>
                          <th>Juegos</th>
                          <th>Promedio</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Yoislán Martínez Aranda</td>
                          <td>3</td>
                          <td>15</td>
                          <td>20</td>
                          <td>95 %</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Lázaro el mecánico</td>
                          <td>3</td>
                          <td>15</td>
                          <td>20</td>
                          <td>95 %</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Carlos Alfonso</td>
                          <td>3</td>
                          <td>15</td>
                          <td>20</td>
                          <td>95 %</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>El chiqui</td>
                          <td>3</td>
                          <td>15</td>
                          <td>20</td>
                          <td>95 %</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Motorina Verde</td>
                          <td>3</td>
                          <td>15</td>
                          <td>20</td>
                          <td>95 %</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Eduardo el tigre</td>
                          <td>3</td>
                          <td>15</td>
                          <td>20</td>
                          <td>95 %</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>El custodio</td>
                          <td>3</td>
                          <td>15</td>
                          <td>20</td>
                          <td>95 %</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Roberto el imaginario</td>
                          <td>3</td>
                          <td>15</td>
                          <td>20</td>
                          <td>95 %</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>El flaco</td>
                          <td>3</td>
                          <td>15</td>
                          <td>20</td>
                          <td>95 %</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                        <th>#</th>
                          <th>Jugador</th>
                          <th>Viajes</th>
                          <th>Pollos</th>
                          <th>Juegos</th>
                          <th>Promedio</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}


          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Noches</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Fecha</th>
                          <th>Jugador (s)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>26/03/2024</td>
                          <td>Yoislán Martínez Aranda</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>27/03/2024</td>
                          <td>Lázaro el mecánico</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>28/03/2024</td>
                          <td>Carlos Alfonso</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>01/04/2024</td>
                          <td>El chiqui</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>02/04/2024</td>
                          <td>Motorina Verde</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>03/04/2024</td>
                          <td>Eduardo el tigre</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>04/04/2024</td>
                          <td>El custodio</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>05/04/2024</td>
                          <td>Roberto el imaginario | Eduardo el tigre</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>06/04/2024</td>
                          <td>El flaco</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Fecha</th>
                          <th>Jugador (s)</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}


          <a
            id="back-to-top"
            href="#"
            className="btn btn-primary back-to-top"
            role="button"
            aria-label="Scroll to top"
          >
            <i className="fas fa-chevron-up"></i>
          </a>
        </body>
      </BodyContainer>
    </>
  );
}

const BodyContainer = styled.body`
  background-color: #343a40;
  h1 {
    color: #f3eded;
  }
  h3 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }
`;

const NavContainer = styled.nav`
  h2 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }

  padding: 0.9rem;
  background-color: #333;
  align-items: center;
  justify-content: space-between;
`;
