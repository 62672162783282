import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import global_ru from "./translations/ru/global.json";
import global_ma from "./translations/ma/global.json";
import global_in from "./translations/in/global.json";
import global_pk from "./translations/pk/global.json";
import global_am from "./translations/am/global.json";
import global_ge from "./translations/ge/global.json";
import global_ro from "./translations/ro/global.json";
import global_ua from "./translations/ua/global.json";
import global_cn from "./translations/cn/global.json";
import global_inb from "./translations/inb/global.json";
import global_pt from "./translations/pt/global.json";
import global_al from "./translations/al/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources:{
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
    ru: {
      global: global_ru,
    },
    ma: {
      global: global_ma,
    },
    in: {
      global: global_in,
    },
    pk: {
      global: global_pk,
    },
    am: {
      global: global_am,
    },
    ge: {
      global: global_ge,
    },
    ro: {
      global: global_ro,
    },
    ua: {
      global: global_ua,
    },
    cn: {
      global: global_cn,
    },
    inb: {
      global: global_inb,
    },
    pt: {
      global: global_pt,
    },
    al: {
      global: global_al,
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
