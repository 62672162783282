import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";

import familia1_1800x800 from "../assets/img/proyecto/derecho a la familia/familia1_1800x800.jpg";
import familia2_1800x800 from "../assets/img/proyecto/derecho a la familia/familia2_1800x800.jpg";
import familia3_1800x800 from "../assets/img/proyecto/derecho a la familia/familia3_1800x800.jpg";
import familia4_1800x800 from "../assets/img/proyecto/derecho a la familia/familia4_1800x800.jpg";

import { useTranslation } from "react-i18next";

export default function Torneo() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="main-header">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("navbar.page1")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                      Nuevo Torneo 
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>


          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Torneo</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Pareja 1</th>
                          <th>Puntos 1</th>
                          <th>Pareja 2</th>
                          <th>Puntos 2</th>
                          <th>J.Ganados</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Yoislán | Roberto</td>
                          <td>125</td>
                          <td>Lázaro | El Flaco</td>
                          <td>54</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Yoislán | Roberto</td>
                          <td>14</td>
                          <td>Motorina V | El Flaco</td>
                          <td>154</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Eduardo | Roberto</td>
                          <td>145</td>
                          <td>Motorina V | El Flaco</td>
                          <td>0</td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Eduardo | Roberto</td>
                          <td>45</td>
                          <td>Yoislán | El Flaco</td>
                          <td>56</td>
                          <td>0</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Pareja 1</th>
                          <th>Puntos 1</th>
                          <th>Pareja 2</th>
                          <th>Puntos 2</th>
                          <th>J.Ganados</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}


          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Puntuación del Día</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Jugador</th>
                          <th>Viajes</th>
                          <th>Pollos</th>
                          <th>Juegos G</th>
                          <th>Juegos P</th>
                          <th>Juegos J</th>
                          <th>Promedio</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Yoislán Martínez Aranda</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>2</td>
                          <td>0.5</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Lázaro el mecánico</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>0.0</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Roberto</td>
                          <td>0</td>
                          <td>1</td>
                          <td>3</td>
                          <td>1</td>
                          <td>2</td>
                          <td>1.50</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Motorina V</td>
                          <td>1</td>
                          <td>0</td>
                          <td>4</td>
                          <td>1</td>
                          <td>2</td>
                          <td>2.0</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>El Flaco</td>
                          <td>1</td>
                          <td>0</td>
                          <td>4</td>
                          <td>2</td>
                          <td>3</td>
                          <td>2.0</td>
                        </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                          <th>#</th>
                          <th>Jugador</th>
                          <th>Viajes</th>
                          <th>Pollos</th>
                          <th>Juegos G</th>
                          <th>Juegos P</th>
                          <th>Juegos J</th>
                          <th>Promedio</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}

        </div>

        <a
          id="back-to-top"
          href="#"
          className="btn btn-primary back-to-top"
          role="button"
          aria-label="Scroll to top"
        >
          <i className="fas fa-chevron-up"></i>
        </a>
      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0.5rem;
  .colordiv {
    background-color: #001952;
  }
  .colortext {
    color: white;
  }
`;

const DivBoton = styled.div`
  padding: 0.4rem;
  background: linear-gradient(to bottom right, #001952 60%, pink);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
