import React from "react";
import wp from "../assets/img/proyecto/wp.png";
import telegram from "../assets/img/proyecto/telegram.png";
import og_yaranda from "../assets/img/proyecto/og_yaranda.png";
import inst from "../assets/img/proyecto/in.png";
import facebook from "../assets/img/proyecto/facebook.png";
import X from "../assets/img/proyecto/X.png";
import youtube from "../assets/img/proyecto/youtube.png";
import { useTranslation } from "react-i18next";
import Mailer from "./Mailer";

export default function Footer() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <footer
        className="fl-page-footer-wrap"
        itemScope="itemscope"
        itemType="https://schema.org/WPFooter"
        role="contentinfo"
      >
        <div
          className="fl-row fl-row-full-width fl-row-bg-color fl-node-60b0045d51dfd fl-row-default-height fl-row-align-center"
          data-node="60b0045d51dfd"
        >
          <div className="fl-row-content-wrap">
            <div className="fl-row-content fl-row-fixed-width fl-node-content">


{/* Main content */}
<section className="content">
  <div className="container-fluid">
    <div className="row">
      {/* left column */}
      <div className="col-md-6">
        <br></br>

        {/* general form elements */}
        <div className="">
          <div className="card-header">
            <p>{t("body.texto17")}</p>
          </div>
          {/* /.card-header */}
          {/* form start */}


          <div
                className="fl-col-group fl-node-60b0033d27911 fl-col-group-custom-width"
                data-node="60b0033d27911"
              >
                <div
                  className="fl-col fl-node-60b0033d27919 fl-col-has-cols"
                  data-node="60b0033d27919"
                >
                  <div className="fl-col-content fl-node-content">
                    <br></br><br></br>
                    <div
                      className="fl-col-group fl-node-60b0033d2792d fl-col-group-nested"
                      data-node="60b0033d2792d"
                    >
                      <div
                        className="fl-col fl-node-60b0033d2792e"
                        data-node="60b0033d2792e"
                      >
                        <div className="fl-col-content fl-node-content">
                          <div
                            className="fl-module fl-module-rich-text fl-node-60b0033d27930"
                            data-node="60b0033d27930"
                          >
                            <div className="fl-module-content fl-node-content">
                              <div className="fl-rich-text">
                                <p>
                                  <i className="fas fa-phone" />
                                  <strong> {t("body.texto18")}</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fl-col-group fl-node-60b0033d2792d fl-col-group-nested"
                      data-node="60b0033d2792d"
                    >
                      <div
                        className="fl-col fl-node-60b0033d2792e"
                        data-node="60b0033d2792e"
                      >
                        <div className="fl-col-content fl-node-content">
                          <div
                            className="fl-module fl-module-rich-text fl-node-60b0033d27930"
                            data-node="60b0033d27930"
                          >
                            <div className="fl-module-content fl-node-content">
                              <div className="fl-rich-text">
                                <p>
                                  <i className="fas fa-envelope" />
                                  <strong> {t("body.texto20")}</strong>
                                </p>
                              </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                </div>
              </div>


        </div>
        {/* /.card */}
      </div>
      {/*/.col (left) */}
      {/* right column */}
      <div className="col-md-6">
        <br></br>
        {/* general form elements disabled */}
        <div className="">
          <div className="card-header">
            <p>{t("body.texto22")}</p>
          </div>
          {/* /.card-header */}
          <div className="card-body">

          <Mailer />


          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </div>
      {/*/.col (right) */}
    </div>
    {/* /.row */}
  </div>{/* /.container-fluid */}
</section>



            </div>
          </div>
        </div>

        <div className="fl-page-footer">
          <div className="fl-page-footer-container container">
            <div className="fl-page-footer-row row">
              <div className="col-md-12 text-center clearfix">
                <div className="fl-page-footer-text fl-page-footer-text-1">
                  Copyright 2024 © Yoislán Martínez Aranda | {t("footer.texto2")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
